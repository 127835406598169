import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import {
  Name,
  Email,
  CheckInCheckOut,
  Phone,
  Adults,
  Rooms,
  SpecialRequests,
  Notes,
  Children,
  NotificationPreference,
  VIPGuest,
} from '../components/form/Inputs';

import { actionTypes, isValidAction, showErrorToast, showSuccessToast } from '../utils';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { MultipleSelectCheckmarks } from '../components/form/MultipleSelectCheckmarks';
import { getVenueById } from '../utils';
import { httpsCallable } from 'firebase/functions';

export const DashboardForm: React.FC<any> = ({
  isOpen,
  setIsOpen,
  onAddToWaitlist,
  values,
  venueUid
}) => {
  const { db, user, currentManager, functions } = useContext(FirebaseContext);

  const [checkin, setCheckin] = useState<Date | null>(values?.checkin?.toDate() || null);
  const [checkout, setCheckout] = useState<Date | null>(values?.checkout?.toDate() || null);
  const [adults, setAdults] = useState<number>(values?.adults || 1);
  const [children, setChildren] = useState<number>(values?.children  || 0);
  const [rooms, setRooms] = useState<number>(values?.rooms || 1);
  const [reasonCode] = useState<string>(values?.reason_code || '');
  const [vip] = useState<boolean>(values?.vip_guest || false);
  const [loading, setLoading] = useState<boolean>(false);
  const [venue, setVenue] = React.useState<any>();
  
  const labelForAllRoomTypes = 'Any Room Type'

  useEffect(() => {
    (async () => {
      const venueData = await getVenueById(db, venueUid || values.venue_uid);
      setVenue(venueData);
    })();
    if (!venueUid) {
      alert('Venue details are missing.  Please contact the administrator.')
      setIsOpen(false);
    }
  }, [db, venueUid, values, setIsOpen])

  const handleAddToWaitlist = async (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      if (values && values.is_archived) { return }

      const form = new FormData(e.target);
      const notificationPreference = form.get('notificationPreference');

      if (!checkin || !checkout || moment(checkout).isSameOrBefore(moment(checkin))) {
        showErrorToast('Check-Out Date must be after Check-In Date')
        return;
      }
      
      const selectedRoomType = form.get('roomtype')
      const payload: any = {
        id: values?.id || uuid(),
        name: form.get('name'),
        phone: form.get('phone'),
        email: form.get('email'),
        adults,
        children,
        rooms,
        checkin,
        checkout,
        notify_email: notificationPreference === 'email' || notificationPreference === 'both',
        notify_phone: notificationPreference === 'phone' || notificationPreference === 'both',
        requested_room: selectedRoomType && selectedRoomType !== labelForAllRoomTypes ? selectedRoomType : null,
        vip_guest: form.get('vip_guest') || false,
        reason_code: reasonCode || '',
        availability_warning: false,
        special_requests: values?.special_requests || '',
        notes: form.get('notes'),
        last_notification_date: values?.last_notification_date || null,
        booking_url: getBookingUrl(),
        create_date: values?.create_date || new Date(),
        create_by: values?.create_by || user?.email || '',
        update_date: new Date(),
        update_by: user?.email || '',
        last_update_by: currentManager ? currentManager.first_name + ' ' + currentManager.last_name : user?.email || '',
        is_archived: false
      };
      await onAddToWaitlist(venueUid, payload);
      setIsOpen(false);
  }

  const getBookingUrl = () => {
    if (values && values.booking_url) {
      const bookingURL = new URL(values.booking_url);
      if (bookingURL.searchParams.get('rooms')) bookingURL.searchParams.set('rooms', rooms.toString());
      if (bookingURL.searchParams.get('adult')) bookingURL.searchParams.set('adult', adults.toString());
      if (bookingURL.searchParams.get('child')) bookingURL.searchParams.set('child', children.toString());
      if (bookingURL.searchParams.get('arrive')) bookingURL.searchParams.set('arrive', moment(checkin).format('YYYY-MM-DD'));
      if (bookingURL.searchParams.get('depart')) bookingURL.searchParams.set('depart', moment(checkout).format('YYYY-MM-DD'));
      bookingURL.searchParams.delete('promo');
      bookingURL.searchParams.delete('group');
      return bookingURL.toString();
    } else if (venue && venue.venue_url) {
      return venue.venue_url;
    } else {
      return '';
    }
  }
  
  const handleUnarchive = async () => {
    if (values && values.is_archived) {
      const payload = {
        ...values,
        is_archived: false
      }
      await onAddToWaitlist(venueUid, payload);
      setIsOpen(false);
    }
  }

  const resetForm = () =>{
    setIsOpen(false); 
    setCheckin(null); 
    setCheckout(null);
  }

  const triggerRecommendationEngine = async (venueUid: String, waitlistUid: String) => {
      setLoading(true);
      const runRecommendationEngine = httpsCallable(functions, 'recommendation-runRecommendationEngine');
      const response = await runRecommendationEngine({venueUid, waitlistUid});
      if (response && !!response.data) {
        showSuccessToast('Success');
      } else {
        showErrorToast('Request Failed.  Check for recommendations_enabled flag')
      }
      setLoading(false);
  }
  return (
    <Dialog open={isOpen} onClose={(event, reason) => {
      if (reason === 'backdropClick') { 
        return
      } else {
        resetForm()
      }
    }}>
      <DialogTitle>
        {values && values.is_archived ? `View Archived Waitlist at ${venue?.venue_name || ''}` : values?.id ? `Update Waitlist at ${venue?.venue_name || ''}` : `Add to Waitlist at ${venue?.venue_name || ''}`}
        {values && values.reservations && Object.keys(values.reservations).length > 0 ? <div style={{fontSize: 16, color: 'green', fontWeight: 'bold'}}>Reservation Status: {Object.keys(values.reservations).length > 1 ? 'Multiple Reservations': (Object.values(values.reservations)[0] as any).reservation_status}</div> : <></>}
        {values && values.availability_warning && currentManager && currentManager.is_super_admin ? <div style={{fontSize: 16, color: 'purple', fontWeight: 'bold'}}>(Admin view only: Room was available at guest registration)</div> : <></>}
      </DialogTitle>
      <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleAddToWaitlist}>
        <DialogContent>
          <Name defaultValue={values?.name || ''} />
          <Phone defaultValue={values?.phone || ''}/>
          <Email defaultValue={values?.email || ''}/>
          <Typography textAlign='left' style={{ marginTop: 8, fontSize: '1rem' }}>
            Notification preference:
          </Typography>
          <NotificationPreference
            defaultValue={!values?.id || (values.notify_email && values.notify_phone) ? 'both': values?.notify_email ? 'email' : 'phone' }
          />
          <div>
            <CheckInCheckOut
              setCheckin={setCheckin}
              checkin={checkin}
              setCheckout={setCheckout}
              checkout={checkout}
            />
          </div>
          {user?.uid && venue && venue.room_types && venue.room_types.length > 0 ?
            <MultipleSelectCheckmarks 
              optionList={venue.room_types || []} 
              defaultValue={values?.requested_room || null}  
              listItemId='display_name'
              sortKey='sort_order' 
              allLabel={labelForAllRoomTypes}
              formId='roomtype' 
              formLabel='Room Type'
            />
          : <></>}
          <Adults adults={adults} setAdults={setAdults} />
          <Children children={children} setChildren={setChildren} />
          <Rooms rooms={rooms} setRooms={setRooms} />
          <VIPGuest vip={vip} />
          {values && values.id ? <SpecialRequests defaultValue={values.special_requests || ''} /> : <></>}
          <Notes defaultValue={values?.notes || ''} />
        </DialogContent>
        <DialogActions style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          {venueUid && isValidAction(actionTypes.DELETE_WAITLIST_REQUEST, currentManager, venueUid)  && values && values.is_archived && <Button variant='outlined' style={{minWidth: 200}} disabled={loading} onClick={handleUnarchive}>
            Unarchive
          </Button>}
          {venue && venue.recommendations_enabled && venue.room_rankings && venue.room_rankings.length > 0 && values && values.id && currentManager && currentManager.is_super_admin ? 
          <Button variant='outlined' style={{minWidth: 200}} disabled={loading} onClick={() => triggerRecommendationEngine(venueUid, values.doc_id)}>
            {loading ? <CircularProgress size={24} /> : "Run Recommendations"}
          </Button> : <div />}
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Button style={{marginRight: 12}} onClick={() => resetForm()}>{values && values.is_archived ? 'CLOSE' : 'CANCEL'}</Button>
            <Button style={{display: values && values.is_archived ? 'none' : 'inherit'}} type="submit" variant="contained">
              {values && values?.id ? 'Update Waitlist' : 'Add to Waitlist'}
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};


// <FormControl
          //   sx={{ marginTop: 0.5, width: '100%' }}>
          //   <InputLabel id="simple-select-label">Reason not available</InputLabel>
          //   <Select
          //     id="reason_code"
          //     name="reason_code"
          //     fullWidth
          //     label='Reason not available'
          //     value={reasonCode || ''}
          //     displayEmpty
          //     IconComponent={
          //       reasonCode.length > 1
          //         ? () => (
          //             <IconButton
          //               size="small"
          //               sx={{ marginRight: 1 }}
          //               onClick={() => setReasonCode('') }
          //             >
          //               <ClearIcon />
          //             </IconButton>
          //           )
          //         : undefined
          //     }
          //     onChange={(e: any) => setReasonCode(e.target.value)}
          //   >
          //     <MenuItem key={'TFA'} value={'TFA'}>
          //       <ListItemText>Too Far in Advance (TFA)</ListItemText>
          //     </MenuItem>
          //     <MenuItem key={'HSO'} value={'HSO'}>
          //       <ListItemText>Hotel Sold Out (HSO)</ListItemText>
          //     </MenuItem>
          //     <MenuItem key={'RSO'} value={'RSO'}>
          //       <ListItemText>Room type Sold Out (RSO)</ListItemText>
          //     </MenuItem>
          //     <MenuItem key={'MNS'} value={'MNS'}>
          //       <ListItemText>Minimum Night Stay required (MNS)</ListItemText>
          //     </MenuItem>
          //   </Select>
          // </FormControl>